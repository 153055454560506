import React from "react";
import Chat, { Bubble, useMessages } from "@chatui/core";
import axios from "axios";
import "@chatui/core/dist/index.css";

export default function App() {
  const { messages, appendMsg, setTyping } = useMessages([]);

  function handleSend(type, val) {
    if (type === "text" && val.trim()) {
      appendMsg({
        type: "text",
        content: { text: val },
        position: "right"
      });

      setTyping(true);

      axios.get("http://swap-api.juhuan.top/chat/defaultSend?str="+val).then((response)=>{
        console.log(response.data.data)
        appendMsg({
          type: "text",
          content: { text: response.data.data }
        });
      })
    }
  }

  function renderMessageContent(msg) {
    const { content } = msg;
    return <Bubble content={content.text} />;
  }

  return (
    <Chat
      navbar={{ title: "小鬼" }}
      messages={messages}
      renderMessageContent={renderMessageContent}
      onSend={handleSend}
    />
  );
}
